* {
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 0;
}

:focus {
  outline: none !important;
}

/**:focus-visible {*/
/*  outline: none;*/
/*}*/

html {
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding-right: 0 !important;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
  text-underline-position: under;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul,
ol {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
