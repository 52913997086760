.header-container {
  margin-top: 10px;
  height: 30vh;
  width: screen;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle, transparent 60%, hsl(0, 72%, 60%) 40%);
}

.header-text {
  color: white;
  text-transform: uppercase;
  font-size: 3em;
  font-weight: 500;
  text-align: center;
}

#header-text2 {
  color: rgb(228, 27, 27);
}

.eventcard-container {
  width: 100%;
  margin: auto;
}

.why-book {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.why-book .why-image img {
  height: 400px;
}

.why-text {
  width: 50%;
}

.why-heading p {
  text-align: center;
  font-size: 32px;
  font-weight: 400;
}

/* accordion styles */

/*Section used for centering process*/
section {
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  /* width: 100%; */
  margin: 0 auto;
  padding: 0 1.5rem;
}

.accordion-item {
  background-color: transparent;
  border-radius: 0.4rem;
}

.accordion-item hr {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.accordion-link {
  font-size: 1.6rem;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.accordion-link h3 {
  font-weight: 500;
  font-size: 20px;
}

.accordion-link i {
  color: #e7d5ff;
  padding: 0.5rem;
}

.accordion-link ul {
  display: flex;
  align-items: flex-end;
  list-style-type: none;
  margin-left: 25px;
}

.accordion-link li {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.4);
  padding: 0 0 1px 5px;
}

.accordion-link div {
  display: flex;
}

.accordion-link .ion-md-arrow-down {
  display: none;
}

.answer {
  max-height: 0;
  overflow: hidden;
  position: relative;
  background-color: transparent;
}

.answer p {
  color: #fff;
  font-size: 15px;
  padding-bottom: 1rem;
}

.accordion-item:target .answer {
  max-height: 20rem;
}

.accordion-item:target .accordion-link .ion-md-arrow-forward {
  display: none;
}

.accordion-item:target .accordion-link .ion-md-arrow-down {
  display: block;
}
/* accordion styles end */

@media only screen and (max-width: 600px) {
  .header-text {
    font-size: 1.8em;
  }

  .eventcard-container {
    max-width: 90%;
    margin: auto;
    margin-left: 3em;
  }

  .why-book {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .why-book .why-image img {
    height: 300px;
    padding-left: 2em;
  }

  .why-text {
    width: 100%;
  }

  .why-heading p {
    margin-left: 0.8em;
    font-size: 24px;
  }

  .header-container {
    margin-top: 10px;
    height: 40vh;
    width: screen;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, transparent 60%, hsl(0, 72%, 60%) 70%);
  }
}

/* googleplay button */
.google-play {
  width: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 2em;
}

.google-play:hover {
  background-color: #ebe5e55a;
  color: black;
}

.app-play {
  width: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 2em;
}

.app-play:hover {
  background-color: #ebe5e55a;
  color: black;
}
